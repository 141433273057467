
#port-nav-web {
    width: 100%;
    margin-bottom: 3rem;
    text-align: center;
    margin-top: 1.5rem;
}


#port-nav-web .menu{
 width: 90%;
 margin-left: auto;
 margin-right: auto;

}



  #port-nav-web .nav-text {
    line-height: 0;
  }



#port-nav-web .nav-text:not(:last-child):after {
    content: " | ";
    margin-left: 15px;
    margin-right: 15px;
    color:#ededed;

   }
   
#port-nav-mobile {
  width: 100%;
  margin-bottom: 3rem;
  text-align: center;
  letter-spacing: normal;
  display: block;
  margin-top: 1.5rem;
}
   #port-nav-mobile .menu {
    width: 100%;
    max-width: 400px;
    padding: 0 5px 0 5px;
    margin-left: auto;
    margin-right: auto;
   }

   #port-nav-mobile .nav-text:not(:last-child):not(:nth-child(3)):after {
    content: " | ";
    margin-left: 10px;
    margin-right: 10px;

   }
   
   #port-nav-mobile .nav-text:not(:last-child):not(:nth-child(3)):after {
    content: " | ";
    margin-left: 10px;
    margin-right: 10px;
    color:#ededed;

   }
   


   #port-nav-mobile :nth-child(n+3):after {
    content: "\A";
    white-space: pre;

   }