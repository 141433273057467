#minibio-web .container {
    width: 100%;
    display: flex;
    align-items: flex-start;
    display: flex;
    margin-top: 3.5rem;
    margin-bottom: 2.5rem;
    padding: 0;

}

#minibio-web .left {
    height: 300px;
    /* width: 40%; */
    min-width: 325px;
    float: left;
    margin-right: 2rem;
    
}

#minibio-web .right {
    /* width: 60%; */
    float: right;
    min-width: 550px;

}

.about-photo {
    width: 100%;
}

#minibio-mobile {
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
}

#minibio-mobile .img-holder {
    width: 100%;
}

#minibio-mobile .minibio-pic {
    width: 100%;
    margin-top: 0;
}

.web-img-holder {
    width: 100%;
}


.minibio-pic {
    width: 100%;
}

#minibio-mobile .about-photo {
    width: 30%;
    margin: auto;
}


div.bio.section-spacer {
    margin-bottom: 2rem;
}