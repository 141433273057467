#contact-bar-holder-web {
    width: 100%; 
    display: flex;
    margin-top: 3rem;
    margin-bottom: 3.5rem;
    padding: 0;
    align-items: flex-end;
}

#contact-bar-holder-web .left {
    width: 30%;

    
}

#contact-bar-holder-web .right {
    width: 70%;
    
    
}

#contact-bar-holder-mb {
    width: 100%; 
    margin-top: 3rem;
    margin-bottom: 3.5rem;
    padding: 0;
    text-align: center;
}

#contact-bar-holder-wmb .top {
    width: 100%;
    display: inline;
    text-align: center;

}

#contact-bar-holder-mb .middle {
    width: 100%;
    padding: 0;
    margin-bottom: 1.5rem;
    
}

