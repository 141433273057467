.main-logo-holder {
    width: 100%;
    text-align: center;
    margin-top: 3rem;
  }
  

.melissa-logo {
    width: 30%;
    min-width: 300px;
    /* text-align: center; */
  }

