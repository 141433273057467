div.container {
    padding: 0;
    margin: 0;
}

@media (min-width: 768px) {
    
.contactForm {
    margin-left: 18px;
}

 
}