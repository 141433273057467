#footer {
    height: 100px;
    min-width: 100%;
    float: left;
   margin-top: 0rem;
}

#footer p {
    font-size: .6rem;
    letter-spacing: .1rem;
    font-weight: 600;
    color: #6d6e71;
    line-height: 0;
}
#footer .top-bar {
    min-width: 100%;
    text-align: center;
    background-color: white;
    padding: 10px;

}
#footer .bottom-bar {
    background-color: #f8f9fa;
    min-width: 100%;
    text-align: center;
    margin-bottom: 0;
    padding: 1rem 1rem .2rem 1rem;
}

#footer .bottom-bar .contents {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

#footer .ms-logo {
    width: 65px;
    margin-bottom: 1rem;
  }


