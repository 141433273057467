.inputField__field {
    background-color: white;
    border-style: hidden hidden solid hidden;
    border-width: 1.5px;
    border-color: black;
    font-weight: 600;
  letter-spacing: .1rem;
  font-size: 12px;
  width: 100%;
  height: 2.5rem;
}

.input-textarea {
    background-color: white;
    border-style: hidden hidden solid hidden;
    border-width: 1.5px;
    border-color: black;
    
    font-weight: 600;
  letter-spacing: .1rem;
  font-size: 12px;
  width: 100%;
  resize: none;
}


.inputField__label {
    width: 100%;
}

.submit-button {
    background-color: black;
    color: white;
    width: 100%;
    margin-top: 2rem;
    height: 2rem;
    border: none;
    border-radius: 0;
}

.uppercase {
 text-transform: uppercase;
}

.title-case {
    text-transform: capitalize;
}

.lowercase {
    text-transform:lowercase;
}

@media (max-width: 767.98px) {
    /* hides divs on screen size 575.98 and smaller */
    .input-textarea {
        border-radius: 0;
        
    }
    .inputField__field {
        border-radius: 0;
    }
  
       
    }