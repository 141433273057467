html, #root, body {
  height: 100% !important;
}

body {
  margin: 0;
  font-family: 'Manrope', sans-serif;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: black;
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  letter-spacing: .2rem;
  font-size: 18px;
  font-weight: 700;
}

h2 {
  letter-spacing: .1rem;
  font-size: 13px;
  font-weight: 800;
  line-height: 0;
}

p {
  font-weight: 600;
  letter-spacing: .1rem;
  font-size: 12px;
}

hr {
  color: #e2e2e2;
}

button {
  border: none;
}

.nav-text {
  font-weight: 600;
  letter-spacing: .2rem;
  font-size: 12px;
  text-transform: lowercase;
}

.page-wrapper {
  margin-left: auto;
  margin-right: auto;
  height: 100%;
} 

.content {
  margin-left: auto;
  margin-right: auto;
  max-width: 960px;
  min-width: 375px;
  padding: 0 15px 0 15px;
  min-height: 100vh;
}

.height {
  min-height: 100vh;
  /* margin-bottom: -142px;  */
}

.nav-content {
  margin:0 auto;
  max-width: 960px; 
  padding: 0 15px 0 15px;
}


.footer-content {
  margin:0 auto;
  max-width: 960px; 
  display: flex;
  width: 100%;
  padding: 30px 15px 30px 15px;
  justify-content: space-between;
  align-items: flex-start;

}

.section-spacer {
  margin-bottom: 3.5rem;
  margin-top: 1rem;
}

.center {
  text-align: center;
  margin-top: 1rem;
}


/* media queries - hide divs on certain devices */


@media (max-width: 767.98px) {
  /* hides divs on screen size 575.98 and smaller */
      .xs-hidden {
          display: none;
      }
      /* .shrink {
          max-width: 500px;
          margin-left: auto;
          margin-right: auto;
      } */
  }
  .mobile-max {
      max-width: 500px;
      margin-left: auto;
      margin-right: auto;
  }
  
  
  @media (max-width: 959.98px) and (min-width: 768px) {
      .md-hidden {
          display: none;
      }
      body {
          min-width: 830px;
      }
  
     
  }
  
  .tab-max {
      max-width: 800px;
      margin-left: auto;
      margin-right: auto;
  }
  
  
  @media (min-width: 768px) {
      /* hides divs on screen size 768 and bigger */
      .lg-hidden {
          display: none!important;
      }
  
      body {
          min-width: 1022px;
      }
   
  }

  input:focus {
    outline: #e2e2e2;
  }

textarea:focus {
    outline: #e2e2e2;
  }

  .read-or-hide {
    color: blue;
  }