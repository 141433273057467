.mc__field-container {
    width: 100%;

    
}

.input-width {
    width: 100%;
}



@media (min-width: 768px) {
    
    .mc__form {
    margin-right: 20px;
}

 
}