#photo-bar-web {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 3.5rem;
}


.photo-bar-img {
    width: 100%;
    height: 100%;  
    object-fit: cover;
   }

.photo-holder {
    width: 33%;
}

@media (max-width: 767.98px) {
    /* hides divs on screen size 575.98 and smaller */
    #photo-bar-web {
        margin-bottom: 2px;
    }
    
    }